"use client";

import React, { useRef } from "react";
import { AnimatePresence, motion, useInView } from "framer-motion";
import { Network, Rocket, Target, Zap } from "lucide-react";
import SectionTitle from "../Common/SectionTitle";

const WhyUs = () => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: {
      y: 50,
      opacity: 0,
      scale: 0.8
    },
    visible: {
      y: 0,
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100
      }
    },
    hover: {
      scale: 1.05,
      rotate: 0,
      transition: {
        type: "spring",
        stiffness: 300
      }
    }
  };

  const features = [
    {
      icon: Network,
      title: "Dual-Focus Ecosystem",
      description: "We bridge the gap between job seekers and hiring agencies, creating a win-win scenario for both",
      color: "bg-blue-100",
      iconColor: "text-blue-600",
      gradient: "from-blue-100 to-blue-200"
    },
    {
      icon: Target,
      title: "Proven Expertise",
      description: "Built with deep knowledge of recruitment challenges, tailored to deliver results",
      color: "bg-green-100",
      iconColor: "text-green-600",
      gradient: "from-green-100 to-green-200"
    },
    {
      icon: Rocket,
      title: "Innovative Technology",
      description: "Cutting-edge AI tools ensure precision, speed, and scalability in hiring",
      color: "bg-purple-100",
      iconColor: "text-purple-600",
      gradient: "from-purple-100 to-purple-200"
    },
    {
      icon: Zap,
      title: "Results-Driven",
      description: "Our solutions don't just simplify hiring—they deliver measurable success",
      color: "bg-orange-100",
      iconColor: "text-orange-600",
      gradient: "from-orange-100 to-orange-200"
    }
  ];

  return (
    <section
      ref={ref}
      className="text-class py-16 overflow-hidden"
    >
      <div className="container mx-auto px-4">
        <SectionTitle
          title="Why Choose Us?"
          paragraph="Simplify recruitment with cutting-edge AI technology, designed to accelerate hiring, eliminate biases, and provide in-depth candidate evaluation"
          mb="2rem"
          center
          center_sm
        />
        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={isInView ? "visible" : "hidden"}
          className="grid md:grid-cols-2 lg:grid-cols-4 gap-8"
        >
          <AnimatePresence>
            {features.map((feature, index) => {
              const FeatureIcon = feature.icon;
              return (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  whileHover="hover"
                  className={`relative group overflow-hidden bg-white shadow-lg rounded-2xl p-6 text-center transform transition-all duration-300 hover:shadow-2xl hover:bg-gradient-to-br ${feature.gradient}`}
                >
                  {/* Floating Background Effect */}
                  <div
                    className="absolute -top-10 -right-10 w-32 h-32 bg-opacity-20 rounded-full group-hover:bg-white transition-all duration-300"></div>

                  {/* Icon Container */}
                  <motion.div
                    initial={{ rotate: 0 }}
                    whileHover={{ rotate: 15 }}
                    transition={{ duration: 0.3 }}
                    className={`mx-auto mb-4 w-20 h-20 flex items-center justify-center ${feature.iconColor} ${feature.color} rounded-full shadow-md relative z-10`}
                  >
                    <FeatureIcon size={48} strokeWidth={1.5} />
                  </motion.div>

                  {/* Content */}
                  <h3 className="text-xl font-semibold mb-3 text-gray-800 relative z-10">
                    {feature.title}
                  </h3>
                  <p className="font-normal text-gray-700 dark:text-gray-400 relative z-10">
                    {feature.description}
                  </p>
                </motion.div>
              );
            })}
          </AnimatePresence>
        </motion.div>
      </div>
    </section>
  );
};

export default WhyUs;