"use client"
import React, { useEffect, useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

const METRICS_DATA = [
  {
    value: '25%',
    description: 'improvement in time to fill'
  },
  {
    value: '30%',
    description: 'increase in hiring'
  },
  {
    value: '50%',
    description: 'more efficient spending to attract talent'
  },
  {
    value: '25%',
    description: 'recruiter hours saved per week'
  }
];

const MetricCard = ({ value, description }) => (
  <div className="bg-white p-6 rounded-lg shadow-md text-center transform transition-transform hover:scale-105">
    <div className="text-6xl font-bold text-blue-500 mb-2 metric-value">
      {value}
    </div>
    <p className="text-gray-700">{description}</p>
  </div>
);

const Statistics = () => {
  const metricsRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const ctx = gsap.context(() => {
      const metrics = gsap.utils.toArray('.metric-value');
      
      metrics.forEach((metric, index) => {
        gsap.fromTo(
          metric as Element,
          { 
            opacity: 0, 
            y: 50 
          },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            delay: index * 0.2, // Staggered animation
            ease: 'power2.out',
            scrollTrigger: {
              trigger: metric as Element,
              start: 'top 80%',
              toggleActions: 'play none none reverse',
               // Debug markers in development
            }
          }
        );
      });
    }, metricsRef);

    return () => ctx.revert(); // Cleanup animations on unmount
  }, []);

  return (
    <div 
      className="text-class grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-4xl mx-auto p-4"
      ref={metricsRef}
    >
      {METRICS_DATA.map((metric, index) => (
        <MetricCard
          key={index}
          value={metric.value}
          description={metric.description}
        />
      ))}
    </div>
  );
};

export default Statistics;