const SectionTitle = ({
                        title,
                        paragraph,
                        width = "700px",
                        center,
                        mb = "100px",
                        center_sm
                      }: {
  title: string;
  paragraph: string;
  width?: string;
  center?: boolean;
  mb?: string;
  center_sm?: boolean;
}) => {

  return (
    <>
      <div
        className={`w-full ${center ? "mx-auto lg:text-center" : ""} ${center_sm ? "text-center" : ""}`}
        style={{ maxWidth: width, marginBottom: mb }}
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-anchor-placement="top-bottom"
        data-aos-duration="500"
      >
        <div className="flex items-center mb-4">
          <span className="h-1 flex-grow bg-blue-500" />
          <h2 className="mx-6 text-3xl font-bold !leading-tight text-black dark:text-white sm:text-4xl md:text-[45px]">
            {title}
          </h2>
          <span className="h-1 flex-grow bg-blue-500" />
        </div>
        <p className="text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
          {paragraph}
        </p>
      </div>
    </>
  );
};

export default SectionTitle;
