import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/app/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Contact/index.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/components/CSS/styles.css");
;
import(/* webpackMode: "eager", webpackExports: ["FAQItem"] */ "/app/src/components/FAQ/faq-item.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Statistics/Statistics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/Testimonials/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/WhyUs/WhyUs.tsx");
