"use client";
import SectionTitle from "@/components/Common/SectionTitle";
import React, { useRef, useState } from "react";
import School from "@mui/icons-material/School";
import Star from "@mui/icons-material/Star";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Quote from "@mui/icons-material/FormatQuote";
import Image from "next/image";
import { Scale } from "@mui/icons-material";

const productData = [
  {
    product_name: "MockMate",
    image_url: "https://img.freepik.com/premium-vector/young-man-working-laptop-computer-having-idea-freelance-job-creativity-innovation-business-idea-concept-3d-vector-people-character-illustration-cartoon-minimal-style_365941-795.jpg",
    product_icon: School,
    product_cards: [
      {
        name: "Sarah Chen",
        role: "Software Engineer",
        company: "Oracle",
        image: "https://avatar.iran.liara.run/public/girl",
        quote: "This AI platform revolutionized how I prepare for technical interviews. It's been a game-changer in landing offers from top tech companies!",
        rating: 5
      },
      {
        name: "Michael Rodriguez",
        role: "Data Scientist",
        company: "Meta",
        image: "https://avatar.iran.liara.run/public/boy",
        quote: "The real-time feedback on my coding and problem-solving skills was invaluable. I secured my dream job thanks to MockMate!",
        rating: 5
      },
      {
        name: "Emily Thompson",
        role: "Frontend Developer",
        company: "Amazon",
        image: "https://avatar.iran.liara.run/public/girl",
        quote: "It's like having a personal coding coach. My interview confidence has skyrocketed!",
        rating: 5
      },
      {
        name: "David Kim",
        role: "DevOps Engineer",
        company: "Apple",
        image: "https://avatar.iran.liara.run/public/boy",
        quote: "The detailed analytics helped me pinpoint my weak areas. The improvement in my technical skills has been phenomenal.",
        rating: 5
      },
      {
        name: "Lisa Patel",
        role: "Web Developer",
        company: "Google",
        image: "https://avatar.iran.liara.run/public/girl",
        quote: "The platform's efficiency and personalized feedback exceeded my expectations. Highly recommended for any tech professional!",
        rating: 5
      }
    ]
  },
  {
    product_name: "TalentBoost",
    image_url: "https://static.vecteezy.com/system/resources/previews/000/162/810/original/now-hiring-vector-illustration.jpg",
    product_icon: Scale,
    product_cards: [
      {
        "name": "Nagendra Kumar",
        "role": "CTO & Founder",
        "company": "gleen.ai",
        "image": "https://avatar.iran.liara.run/public/boy",
        "quote": "We were struggling to keep up with our hiring demands, but AltHire's AI simplified everything. The platform’s ability to assess candidates and provide in-depth reports made it much easier to focus on the right talent. We've been able to hire faster and smarter.",
        "rating": 5
      },
      {
        "name": "Jalaj Dagar",
        "role": "Founder",
        "company": "SpotLYT",
        "image": "https://avatar.iran.liara.run/public/boy",
        "quote": "AltHire AI has been a game changer for us. The AI interviews are incredibly efficient and have helped reduce bias in our hiring process. What used to take days now takes just hours, and the detailed reports provide insights we never had before.",
        "rating": 5
      },
      {
        "name": "HariShankar",
        "role": "Engineer",
        "company": "Neurotask AI",
        "image": "https://avatar.iran.liara.run/public/boy",
        "quote": "AltHire AI has saved us countless hours. We can now pre-screen candidates quickly and focus only on those who truly fit the role. The detailed reports have made decision-making so much clearer. I can’t imagine going back to the old way.",
        "rating": 5
      }
    ]

  }
];

const Testimonials = () => {
  const [selectedProduct, setSelectedProduct] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const scroll = (direction: "right" | "left") => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth * 0.8;
      const newScrollPosition = container.scrollLeft + (direction === "right" ? scrollAmount : -scrollAmount);

      container.scrollTo({
        left: newScrollPosition,
        behavior: "smooth"
      });
    }
  };

  const renderStars = (rating: number) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        className={`w-4 h-4 ${
          index < rating
            ? "text-blue-500 fill-blue-500"
            : "text-gray-300"
        }`}
      />
    ));
  };

  return (
    <section id="products" className="products mt-10 mb-20 px-4 sm:px-0">
      <div className="text-class container mx-auto flex flex-col items-center">
        <SectionTitle
          title="Voice of Customers"
          paragraph="What our customers are saying"
          mb="2rem"
          center
          center_sm
        />
        <div className="flex gap-2 sm:gap-3 flex-wrap justify-center w-full">
          {productData.map((product, index) => (
            <div key={index} className="flex flex-col items-center gap-2">
              {/* Image Container */}
              <div className="w-20 h-20 sm:w-24 sm:h-24 md:w-32 md:h-32 overflow-hidden rounded-lg">
                <Image
                  width={128}
                  height={128}
                  src={product.image_url}
                  alt={product.product_name}
                  className={`w-full h-full object-cover transition-all duration-300 ${
                    selectedProduct === index
                      ? "grayscale-0"
                      : "grayscale hover:grayscale-[50%]"
                  }`}
                />
              </div>

              {/* Button */}
              <div
                className={`flex p-2 sm:p-4 rounded gap-1 cursor-pointer text-sm sm:text-base text-black dark:text-white transition-colors duration-300 ${
                  selectedProduct === index
                    ? "bg-blue-600 dark:bg-dark text-white"
                    : "bg-white drop-shadow-2xl dark:bg-gray-600"
                }`}
                onClick={() => setSelectedProduct(index)}
              >
                {React.createElement(product.product_icon, { className: "mr-2" })}
                {product.product_name}
              </div>
            </div>
          ))}
        </div>

        <div className="p-2 sm:p-4 md:p-8 w-full" id="testimonials">
          <div className="max-w-6xl mx-auto">
            {/* <motion.div
              initial={{opacity: 0, y: 20}}
              animate={{opacity: 1, y: 0}}
              className="max-w-7xl mx-auto mb-4 sm:mb-8"
            >
              <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-indigo-950 flex items-center">
                Success Stories
                <span className="ml-2 sm:ml-6 h-1 flex-grow bg-purple-400" />
              </h1>
            </motion.div> */}

            <div className="relative">
              <button
                onClick={() => scroll("left")}
                className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-2 sm:-translate-x-4 z-10 bg-white/90 p-1 sm:p-2 bg-white/90 p-2 rounded-full shadow-lg hover:bg-white hover:shadow-xl transition-all duration-300"
              >
                <ChevronLeft className="w-4 h-4 sm:w-6 sm:h-6 text-indigo-600" />
              </button>

              <button
                onClick={() => scroll("right")}
                className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 bg-white/90 p-2 rounded-full shadow-lg hover:bg-white hover:shadow-xl transition-all duration-300"
              >
                <ChevronRight className="w-6 h-6 text-indigo-600" />
              </button>

              <div
                ref={scrollContainerRef}
                className="flex overflow-x-hidden scroll-smooth gap-2 sm:gap-4 md:gap-8 py-4 px-1 sm:px-2"
              >
                {productData[selectedProduct].product_cards.map((testimonial, index) => (
                  <div
                    key={index}
                    className="flex-none w-[85%] sm:w-[calc(50%-1rem)] md:w-[calc(33.333%-1.33rem)] bg-gradient-to-br from-blue-100 to-blue-100 rounded-xl sm:rounded-2xl p-3 sm:p-4 md:p-8 shadow-xl hover:shadow-2xl transition-all duration-300 relative"
                  >
                    <Quote
                      className="absolute top-2 right-2 sm:top-4 sm:right-4 w-6 h-6 sm:w-8 sm:h-8 text-indigo-200" />

                    <div className="flex items-center space-x-2 sm:space-x-4 mb-4 sm:mb-6">
                      <Image
                        width={48}
                        height={48}
                        src={testimonial.image}
                        alt={testimonial.name}
                        className="w-12 h-12 sm:w-16 sm:h-16 rounded-full object-cover"
                      />
                      <div>
                        <h5 className="text-base sm:text-xl text-blue-950">
                          {testimonial.name}
                        </h5>
                        <p className="text-sm sm:text-m text-blue-600">
                          {testimonial.role}
                        </p>
                        <p className="text-sm sm:text-base leading-relaxed">
                          {testimonial.company}
                        </p>
                      </div>
                    </div>

                    <div className="flex mb-4">
                      {renderStars(testimonial.rating)}
                    </div>

                    <p className="font-normal text-gray-700 dark:text-gray-400">
                      "{testimonial.quote}"
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
