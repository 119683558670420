"use client";
import { useTheme } from "next-themes";
import React, { useEffect, useRef, useState } from "react";
import SectionTitle from "@/components/Common/SectionTitle";
import dynamic from "next/dynamic";

const LazyCalendlyWidget = dynamic(() => import("react-calendly").then((mod) => mod.InlineWidget), {
  ssr: false,
  loading: () => <p>Loading...</p> // Optional: Show loading text while Calendly widget loads
});

const Contact = () => {
  const theme = useTheme();
  const [isVisible, setIsVisible] = useState(false);
  const calendarRef = useRef(null);

  const darkModeCalendar = theme.theme === "dark" ? {
    backgroundColor: "#000",
    textColor: "#fff",
    primaryColor: "#fff"
  } : {};

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );
    if (calendarRef.current) {
      observer.observe(calendarRef.current);
    }
    return () => {
      if (calendarRef.current) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <section id="contact" className="text-class overflow-hidden py-10">
      <div className="container">
        <SectionTitle
          title="Let's Talk"
          paragraph="Book a demo with us to know more about our products and services"
          mb={"0"}
          center
          center_sm
        />
        <div className="w-full px-1">
          <div ref={calendarRef}>
            {isVisible && (
              <LazyCalendlyWidget
                url="https://calendly.com/deepusingla448/30min"
                styles={{ height: "650px" }}
                pageSettings={{
                  ...darkModeCalendar,
                  hideLandingPageDetails: false,
                  hideGdprBanner: true,
                  hideEventTypeDetails: false
                }}
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
